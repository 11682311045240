<template>
  <div class="Container">
    <Parallax :images="ParallaxImages()" />
    <div class="Container__Content">
      <div class="Container_Blur" />
      <div>
        <p class="Container__TitleText">EMAIL CONFIRMATION</p>
      </div>
      <div class="subtitle">
        <p>{{ message }}</p>
      </div>
      <router-link v-if="forgot" :to="{ name: 'forgotPassword' }">
        <ForgotButton class="Container__Button" />
      </router-link>
    </div>
  </div>
</template>

<script>
import ForgotButton from '../login/components/ForgotButton'
import Parallax from '@/components/parallax/Parallax.vue'
import api from '@/services/api'
import { EventBus } from '@/event-bus.js'
import token from '@/mixins/token'

export default {
  mixins: [token],
  components: {
    ForgotButton,
    Parallax
  },
  data () {
    return {
      message: '',
      forgot: false
    }
  },
  async mounted () {
    try {
      this.setToken('')
      const ans = await api.auth
        .confirmEmail(this.$route.params.token)
        .catch(err => {
          return err.request.status !== 200
            ? new Error(err.request.response)
            : err.request.response
        })
      if (ans instanceof Error) {
        this.message = JSON.parse(ans.message).description
        throw new Error(ans.message)
      }
      this.message = ans.description
      this.$router.push({
        name: 'setPassword',
        path: `/entrance/reset-password/${this.$route.params.token}`,
        params: { firstTime: true }
      })
    } catch (err) {
      this.forgot = true
      const msg = JSON.parse(err.message)
      EventBus.$emit('notify', {
        type: 'error',
        message: msg.name,
        hidden: true
      })
    }
  },
  methods: {
    ParallaxImages () {
      const result = []
      result.push({ src: 'Exterior_03.png', force: 0 })
      result.push({ src: 'Exterior_02.png', force: 10 })
      result.push({ src: 'Exterior_01.png', force: 30 })
      return result
    },
    ParallaxAnimations () {
      const result = []
      result.push({
        src: ['Birds_01.png', 'Birds_02.png', 'Birds_03.png'],
        force: -20
      })
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/main.scss';

.Container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.Container__Image_Bg {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.Container__Content {
  position: relative;
  box-sizing: border-box;
  padding: 2rem 1.5rem;
  height: 350px;
  width: 365px;
  border-radius: 2.5rem;
  background-color: rgba(255, 255, 255, 0.5);
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-height: 610px) and (orientation: landscape) {
    height: 90%;
    padding: 0.5rem 1.5rem;
  }
  @media only screen and (max-width: 400px) {
    width: 90%;
  }
}

.Container_Blur {
  border-radius: 2.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  backdrop-filter: blur(10px);
}

.Container__TitleText {
  font-family: 'Gotham Bold';
  @include fluid-type($min_width, $max_width, $min_font * 1.5, $max_font * 1);
  text-transform: uppercase;
  text-align: center;
  @media only screen and (max-height: 610px) and (orientation: landscape) {
    margin-bottom: 0.5rem;
  }
}
.subtitle {
  p {
    font-family: 'Gotham Book';
    @include fluid-type($min_width, $max_width, $min_font * 0.8, $max_font * 1);
    text-align: center;
  }
}
</style>
