<template>
  <div class="ForgotButton_Container">
    <button class="ForgotButton_Btn" @click="$emit('forgotPassword')">
      Forget password ?
    </button>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.ForgotButton_Container {
  display: flex;
  justify-content: center;
}
.ForgotButton_Btn {
  font-family: 'Gotham Medium';
  border: none;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 0.5rem;
}
</style>
